import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table className="field-table api-ref">
      <thead>
  <tr>
    <td>Name /<br />Type</td>
    <td>Description</td>
  </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Query reference`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "queryref"
            }}><a parentName="h6" {...{
                "href": "#queryref"
              }}><inlineCode parentName="a">{`queryRef`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`QueryRef<TData>`}</inlineCode></p>
          </td>
          <td>
            <p>{`In order to link a query initiated by a specific `}<inlineCode parentName="p">{`useBackgroundQuery`}</inlineCode>{` call to the place its data is consumed—which can be uniquely identified not only by the query and variables passed, but also the optional `}<inlineCode parentName="p">{`queryKey`}</inlineCode>{` supplied by the user—the hook returns a `}<inlineCode parentName="p">{`queryRef`}</inlineCode>{` that can later be read by `}<inlineCode parentName="p">{`useReadQuery`}</inlineCode>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <p><strong parentName="p">{`Helper functions`}</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "refetch"
            }}><a parentName="h6" {...{
                "href": "#refetch"
              }}><inlineCode parentName="a">{`refetch`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`(variables?: Partial<TVariables>) => Promise<ApolloQueryResult>`}</inlineCode></p>
          </td>
          <td>
            <p>{`A function that enables you to re-execute the query, optionally passing in new `}<inlineCode parentName="p">{`variables`}</inlineCode>{`.`}</p>
            <p>{`To guarantee that the refetch performs a network request, its `}<inlineCode parentName="p">{`fetchPolicy`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`network-only`}</inlineCode>{` (unless the original query's `}<inlineCode parentName="p">{`fetchPolicy`}</inlineCode>{` is `}<inlineCode parentName="p">{`no-cache`}</inlineCode>{` or `}<inlineCode parentName="p">{`cache-and-network`}</inlineCode>{`, which also guarantee a network request).`}</p>
            <p>{`Calling this function will cause the component to re-suspend, unless the call site is wrapped in `}<a parentName="p" {...{
                "href": "https://react.dev/reference/react/startTransition"
              }}><inlineCode parentName="a">{`startTransition`}</inlineCode></a>{`.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "fetchmore"
            }}><a parentName="h6" {...{
                "href": "#fetchmore"
              }}><inlineCode parentName="a">{`fetchMore`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`({ query?: DocumentNode, variables?: TVariables, updateQuery: Function}) => Promise<ApolloQueryResult>`}</inlineCode></p>
          </td>
          <td>
            <p>{`A function that helps you fetch the next set of results for a `}<a parentName="p" {...{
                "href": "/react/pagination/core-api/"
              }}>{`paginated list field`}</a>{`.`}</p>
            <p>{`Calling this function will cause the component to re-suspend, unless the call site is wrapped in `}<a parentName="p" {...{
                "href": "https://react.dev/reference/react/startTransition"
              }}><inlineCode parentName="a">{`startTransition`}</inlineCode></a>{`.`}</p>
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      