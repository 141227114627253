import UseFragmentOptions from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/useFragment-options.mdx";
import UseFragmentResult from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/useFragment-result.mdx";
import UseBackgroundQueryOptions from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/useBackgroundQuery-options.mdx";
import UseSuspenseQueryResult from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/useSuspenseQuery-result.mdx";
import UseBackgroundQueryResult from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/useBackgroundQuery-result.mdx";
import UseReadQueryResult from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/useReadQuery-result.mdx";
import { FunctionDetails, PropertySignatureTable, ManualTuple, InterfaceDetails } from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/ApiDoc";
import { UseLoadableQueryResult } from "../../../gatsby-source-git/QubitPi/apollo-client/react/docs/shared/Overrides/UseLoadableQueryResult";
import * as React from 'react';
export default {
  UseFragmentOptions,
  UseFragmentResult,
  UseBackgroundQueryOptions,
  UseSuspenseQueryResult,
  UseBackgroundQueryResult,
  UseReadQueryResult,
  FunctionDetails,
  PropertySignatureTable,
  ManualTuple,
  InterfaceDetails,
  UseLoadableQueryResult,
  React
};