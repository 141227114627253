import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`client.refetchQueries`}</inlineCode>{` method take an `}<inlineCode parentName="p">{`options`}</inlineCode>{` object that conforms to the
following TypeScript interface:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface RefetchQueriesOptions<
  TCache extends ApolloCache<any>,
  TResult = Promise<ApolloQueryResult<any>>,
> {
  updateCache?: (cache: TCache) => void;
  include?: Array<string | DocumentNode> | "all" | "active";
  onQueryUpdated?: (
    observableQuery: ObservableQuery<any>,
    diff: Cache.DiffResult<any>,
    lastDiff: Cache.DiffResult<any> | undefined,
  ) => boolean | TResult;
  optimistic?: boolean;
}
`}</code></pre>
    <p>{`These fields are described below:`}</p>
    <table className="field-table api-ref">
  <thead>
    <tr>
      <th>Name /<br />Type</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
        <tr>
          <td>
            <h6 {...{
              "id": "updatecache"
            }}><a parentName="h6" {...{
                "href": "#updatecache"
              }}><inlineCode parentName="a">{`updateCache`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`(cache: TCache) => void`}</inlineCode></p>
          </td>
          <td>
            <p>{`Optional function that updates cached fields to trigger refetches of queries that include those fields.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "include"
            }}><a parentName="h6" {...{
                "href": "#include"
              }}><inlineCode parentName="a">{`include`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`Array<string | DocumentNode> | "all" | "active"`}</inlineCode></p>
          </td>
          <td>
            <p>{`Optional array specifying queries to refetch. Each element can be either a query's string name or a `}<inlineCode parentName="p">{`DocumentNode`}</inlineCode>{` object.`}</p>
            <p>{`Analogous to the `}<a parentName="p" {...{
                "href": "/react/data/mutations/#options"
              }}><inlineCode parentName="a">{`options.refetchQueries`}</inlineCode></a>{` array for mutations.`}</p>
            <p>{`Pass `}<inlineCode parentName="p">{`"active"`}</inlineCode>{` (or `}<inlineCode parentName="p">{`"all"`}</inlineCode>{`) as a shorthand to refetch all (active) queries.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "onqueryupdated"
            }}><a parentName="h6" {...{
                "href": "#onqueryupdated"
              }}><inlineCode parentName="a">{`onQueryUpdated`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`(observableQuery: ObservableQuery<any>, diff: Cache.DiffResult<any>, lastDiff: Cache.DiffResult<any> | undefined) => boolean | TResult`}</inlineCode></p>
          </td>
          <td>
            <p>{`Optional callback function that's called once for each `}<inlineCode parentName="p">{`ObservableQuery`}</inlineCode>{` that's either affected by `}<inlineCode parentName="p">{`options.updateCache`}</inlineCode>{` or listed in `}<inlineCode parentName="p">{`options.include`}</inlineCode>{` (or both).`}</p>
            <p>{`If `}<inlineCode parentName="p">{`onQueryUpdated`}</inlineCode>{` is not provided, the default implementation returns the
result of calling `}<inlineCode parentName="p">{`observableQuery.refetch()`}</inlineCode>{`. When `}<inlineCode parentName="p">{`onQueryUpdated`}</inlineCode>{` is
provided, it can dynamically decide whether (and how) each query should be
refetched.`}</p>
            <p>{`Returning `}<inlineCode parentName="p">{`false`}</inlineCode>{` from `}<inlineCode parentName="p">{`onQueryUpdated`}</inlineCode>{` prevents the associated query
from being refetched.`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h6 {...{
              "id": "optimistic"
            }}><a parentName="h6" {...{
                "href": "#optimistic"
              }}><inlineCode parentName="a">{`optimistic`}</inlineCode></a></h6>
            <p><inlineCode parentName="p">{`boolean`}</inlineCode></p>
          </td>
          <td>
            <p>{`If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the `}<inlineCode parentName="p">{`options.updateCache`}</inlineCode>{` function is executed on a temporary optimistic layer of `}<inlineCode parentName="p">{`InMemoryCache`}</inlineCode>{`, so its modifications can be discarded from the cache after observing which fields it invalidated.`}</p>
            <p>{`Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`, meaning `}<inlineCode parentName="p">{`options.updateCache`}</inlineCode>{` updates the cache in a
lasting way.`}</p>
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      